<template>
  <div>
    <HeaderPanel
      title="Branch Style"
      :filter="filter"
      placeholder="Search Branch Style"
      @search="getList"
      :hasDropdown="false"
      :hasFilter="false"
      routerPath="/setting/branch-setting/branch-style/0"
    />
    <div class="bg-white border-red p-2 p-lg-3 mt-3">
      <b-row>
        <b-col>
          <b-table
            responsive
            striped
            hover
            :fields="fields"
            :items="items"
            :busy="isBusy"
            show-empty
            empty-text="No matching records found"
          >
            <template v-slot:cell(branch_style_name)="{ item }">
              <router-link
                :to="
                  '/setting/branch-setting/branch-style/' + item.branch_style_id
                "
              >
                {{ item.branch_style_name }}
              </router-link>
            </template>
            <template v-slot:cell(status)="{ item }">
              <span
                :class="item.status == '1' ? 'text-success' : 'text-error'"
                >{{ item.status == "1" ? "Active" : "Inactive" }}</span
              >
            </template>
            <template v-slot:cell(is_default)="{ item }">
              <span>{{ item.is_default == "1" ? "Default" : "" }}</span>
            </template>

            <template v-slot:cell(actions)="{ item }">
              <div class="d-flex justify-content-center">
                <router-link
                  :to="
                    '/setting/branch-setting/branch-style/' +
                    item.branch_style_id
                  "
                >
                  <b-button variant="link" class="text-warning px-1 py-0">
                    <font-awesome-icon icon="pencil-alt" title="Edit" />
                  </b-button>
                </router-link>
                <b-button
                  variant="link"
                  class="text-error px-1 py-0"
                  @click="deleteItems(item.branch_style_id)"
                  :disabled="item.is_default == 1"
                >
                  <font-awesome-icon icon="trash-alt" title="delete" />
                </b-button>
              </div>
            </template>

            <template v-slot:table-busy>
              <div class="text-center text-black my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong class="ml-2">Loading...</strong>
              </div>
            </template>
          </b-table>
        </b-col>
      </b-row>

      <Pagination
        @handleChangeTake="handleChangeTake"
        :pageOptions="pageOptions"
        :filter="filter"
        :rows="rows"
        @pagination="pagination"
      />
    </div>

    <router-view></router-view>
  </div>
</template>
<script>
import HeaderPanel from "@/components/HeaderPanel";

export default {
  components: {
    HeaderPanel,
  },
  data() {
    return {
      pageOptions: [
        { value: 10, text: "10 / page" },
        { value: 30, text: "30 / page" },
        { value: 50, text: "50 / page" },
        { value: 100, text: "100 / page" },
      ],
      filter: {
        search: "",
        page: 1,
        take: 10,
      },
      isBusy: false,
      rows: 0,
      showing: 1,
      showingTo: 10,
      fields: [
        {
          key: "branch_style_name",
          label: "Name",
          class: "w-50",
        },
        {
          key: "is_default",
          label: "Default",
          class: "w-2",
        },
        {
          key: "sort_order",
          label: "Sort Order",
          class: "text-center w-auto",
        },
        {
          key: "status",
          label: "status",
          class: "w-2",
        },

        { key: "actions", label: "Action", class: "w-auto" },
      ],
      items: [],
    };
  },
  created() {
    this.getList();
  },

  methods: {
    async getList() {
      this.isBusy = true;
      const response = await this.axios.post(
        `/branch/GetBranchStyleList`,
        this.filter
      );
      if (response.data.result) {
        this.rows = response.data.detail.total_count;
        this.items = response.data.detail.branch_style_list || [];
      }
      this.isBusy = false;
    },
    handleChangeTake(take) {
      this.filter.page = 1;
      this.filter.take = take;
      this.getList();
    },
    pagination(page) {
      this.filter.page = page;
      this.getList();
    },
    async deleteItems(id) {
      // this.isBusy = true;
      try {
        this.$bus.$emit("showLoading");
        const response = await this.axios.delete(
          `/branch/DeleteBranchStyle/${id}`,
          this.filter
        );
        if (response.data.result) this.successAlert(), await this.getList();
        this.$bus.$emit("hideLoading");
      } catch (error) {
        this.$bus.$emit("hideLoading");
        this.errorAlert(error.message);
      }
      // this.isBusy = false;
    },
  },
};
</script>
